import React, { useState, useEffect, useCallback } from 'react';
import Logo from '../img/PGSOFT_Logo.png';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the menu open state
  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  // Close the menu when clicking outside of it
  const handleClickOutside = useCallback((event) => {
    const menu = document.getElementById('menu');
    if (menu && !menu.contains(event.target)) {
      setMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <nav className="navbar flex justify-between bg-gradient-to-b from-indigo-400 via-pink-400 to-pink-500 p-4 relative">
      <div className='flex content-center items-center'>
    
          <button
            className={`relative group transform transition-transform duration-300 ${menuOpen ? 'rotate-0' : 'rotate-0'}`}
            onClick={toggleMenu}
          >
            <div className="relative flex overflow-hidden items-center justify-center rounded-full w-[30px] h-[30px] transform transition-all bg-pink-400 ring-0 ring-gray-300 hover:ring-8 group-focus:ring-4 ring-opacity-30 duration-200 shadow-sm">
              <div className="flex flex-col justify-between w-[15px] h-[15px] transform transition-all duration-300 origin-center overflow-hidden">
                <div className={`bg-white h-[2px] w-7 transform transition-all duration-300 origin-left ${menuOpen ? 'translate-x-10' : ''}`}></div>
                <div className={`bg-white h-[2px] w-7 rounded transform transition-all duration-300 ${menuOpen ? 'translate-x-10 delay-75' : ''}`}></div>
                <div className={`bg-white h-[2px] w-7 transform transition-all duration-300 origin-left ${menuOpen ? 'translate-x-10 delay-150' : ''}`}></div>
                <div className={`absolute items-center justify-between transform transition-all duration-500 top-2 ${menuOpen ? 'translate-x-0 w-10' : '-translate-x-10 w-0'}`}>
                  <div className={`absolute bg-white h-[2px] w-4 transform transition-all duration-500 rotate-0 ${menuOpen ? 'rotate-45' : ''}`}></div>
                  <div className={`absolute bg-white h-[2px] w-4 transform transition-all duration-500 -rotate-0 ${menuOpen ? '-rotate-45' : ''}`}></div>
                </div>
              </div>
            </div>
          </button>
   
        <div className="pl-3">
          <img src={Logo} alt="Logo" className="w-14 h-auto" />
        </div>
      </div>
      <div className='flex'>
      <button className="bg-transparent hover:bg-pink-500 text-white font-semibold hover:text-white py-1 px-3 bg-pink-400 ring-0 ring-gray-300 hover:ring-8 group-focus:ring-4 ring-opacity-30 duration-200 shadow-sm rounded-full text-sm">
          เข้าสู่ระบบ
        </button>
      </div>
      {/* Mock menu */}
      <div
        id="menu"
        className={`absolute top-16 left-2 bg-pink-400 text-white shadow-lg rounded-lg p-4 transition-opacity duration-300 ${menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      >
        <ul>
          <li className="py-2 px-4 hover:bg-gray-200">Menu 1</li>
          <li className="py-2 px-4 hover:bg-gray-200">Menu 2</li>
          <li className="py-2 px-4 hover:bg-gray-200">Menu 3</li>
          <li className="py-2 px-4 hover:bg-gray-200">Menu 4</li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
