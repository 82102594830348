// components/EventScrollingText.js

import React from 'react';
import { MegaphoneIcon } from '@heroicons/react/24/outline'; 
function EventScrollingText() {
  return (
    <div className="relative bg-black bg-opacity-90 overflow-hidden flex items-center">
       <div className="absolute inset-y-0 left-0 flex items-center space-x-2 pl-2 pr-2 z-10 bg-black">
        <MegaphoneIcon className="w-6 h-6 text-yellow-400 bg-black" />
      </div>
      <p className="whitespace-nowrap inline-block animate-scroll text-white ml-2">
        Mock Scrolling Event
      </p>
    </div>
  );
}

export default EventScrollingText;
