import React from 'react';

function ScrollingText() {
  return (
    <div className="relative bg-black bg-opacity-90 overflow-hidden flex items-center justify-center">
      <p className="whitespace-nowrap inline-block animate-scroll text-white">
        Mock Scrolling Event
      </p>
    </div>
  );
}

export default ScrollingText;
