import React from 'react';
import MobileLayout from './layouts/MobileLayout';
import Home from './pages/Home';
import './index.css'
import './App.css'

const App = () => {
  return (
    <div className="App font-kanit">
      <MobileLayout>
        <Home />
      </MobileLayout>
    </div>
  );
};

export default App;