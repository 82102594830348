import React from 'react';

function Home() {
  return (
    <main className="">
      <h2>Content</h2>
      {/* เพิ่มเนื้อหาที่ต้องการให้ scroll ได้ */}
      {[...Array(50)].map((_, i) => (
        <p key={i}>This is paragraph {i + 1}</p>
      ))}
    </main>
  );
}

export default Home;