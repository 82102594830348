import React from 'react';
import Navbar from '../component/Navbar';
import BottomNav from '../component/BottomNav';
import BgMain from '../img/PG_BH.png';
import ScrollingText from '../component/ScrollingText';
import ImageSlider from '../component/ImageSlider';
import EventScrollingText from '../component/EventScrollingText'

const MobileLayout = ({ children }) => {
  return (
    <div className="app">
      <div className="bg-container" style={{ backgroundImage: `url(${BgMain})` }}></div>
      <Navbar />

      <main className="content">
        <ScrollingText />
        <ImageSlider />
        <EventScrollingText/>
          {children}
      </main>
      <BottomNav className="fixed bottom-9 w-full"/>
    </div>
  );
};

export default MobileLayout;